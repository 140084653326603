import React from 'react';

const SavedPosters = ({ records, isMinimized, setIsMinimized, handleRemove, handleRecordClick }) => {
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        {!isMinimized && <h2 className="text-xl font-bold">Saved Posters</h2>}
        <button onClick={() => setIsMinimized(!isMinimized)} className="text-blue-400 hover:text-blue-300">
          {isMinimized ? '→' : '←'}
        </button>
      </div>
      {records.map((record, index) => (
        <div className="p-2 cursor-pointer" onClick={() => handleRecordClick(record)}>
        <div key={index} className="mb-4 bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-all duration-300 ease-in-out hover:shadow-2xl">
          <div className="relative">
            <img src={record.image} alt="Saved Poster" className="w-full h-32 object-cover" />
            <button 
              onClick={(e) => { e.stopPropagation(); handleRemove(index); }} 
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-300"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          </div>
            {!isMinimized && (
              <>
                <p className="font-bold truncate" style={{ color: record.captionColor }}>{record.caption}</p>
                <p className="text-sm italic truncate overflow-hidden overflow-ellipsis" style={{ color: record.subtextColor, whiteSpace: 'nowrap' }}>{record.subtext}</p>
              </>
            )}
          </div>
        
      ))}
    </>
  );
};

export default SavedPosters;