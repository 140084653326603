
import PosterPreview from './PosterPreview';

import { toPng } from 'html-to-image';
const PosterEditor = ({ image,caption,subtext,captionColor,subtextColor,imageDimensions, posterRef,handleImageLoad,handleSave, setError }) => {




    // State declarations and handlers

  const handleDownload = async () => {
    if (posterRef.current === null) {
      setError('No poster to download. Please create a poster first.');
      return;
    }

    try {
      const dataUrl = await toPng(posterRef.current, { quality: 0.95 });
      const link = document.createElement('a');
      const timestamp = Date.now();
      const sanitizedCaption = caption.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      link.download = `demotivational-poster_${sanitizedCaption}_${timestamp}.png`;
      link.href = dataUrl;
      link.click();
    } catch (err) {
      console.error('Error generating poster:', err);
      setError('Failed to generate poster. Please try again.');
    }
  };

  return (
    <>
        {/* Editor content */}
        <PosterPreview 
          image={image}
          caption={caption}
          subtext={subtext}
          captionColor={captionColor}
          subtextColor={subtextColor}
          imageDimensions={imageDimensions}
          posterRef={posterRef}
        />
        <div className="flex space-x-4">
            <button 
              onClick={handleSave} 
              className={`flex-1 ${image ? 'bg-green-500 hover:bg-green-600': 'bg-gray-300 cursor-not-allowed'  }  text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out flex items-center justify-center shadow-md`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
              Save
            </button>
            <button 
              onClick={handleDownload} 
              disabled={!image}
              className={`flex-1 ${
                image 
                  ? 'bg-indigo-500 hover:bg-indigo-600' 
                  : 'bg-gray-300 cursor-not-allowed'
              } text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out flex items-center justify-center shadow-md`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              Download
            </button>
          </div>
     </>
  );
};

export default PosterEditor;