import React, { useState, useRef, useEffect } from 'react';


import { calculateDimensions } from './utils/imageUtils';
import {BORDER_WIDTH, BORDER_PADDING,MAX_WIDTH , MAX_HEIGHT,COLOR_WHITE} from './constants.js'

import SavedPosters from './components/SavedPosters.js';
import PosterEditor from './components/PosterEditor.js';

const App = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [caption, setCaption] = useState('');
  const [subtext, setSubtext] = useState('');
  const [captionColor, setCaptionColor] = useState(COLOR_WHITE);
  const [subtextColor, setSubtextColor] = useState(COLOR_WHITE);
  const [error, setError] = useState('');
  const [records, setRecords] = useState([]);
  const [isMinimized, setIsMinimized] = useState(true);
  const [imageDimensions, setImageDimensions] = useState({ width: MAX_WIDTH, height: MAX_HEIGHT });
  const posterRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const storedRecords = JSON.parse(localStorage.getItem('demotivationalPosters') || '[]');
    setRecords(storedRecords);
  }, []);



  const handleImageLoad = (event) => {
    const img = event.target;
    const { width, height } = calculateDimensions(img.naturalWidth, img.naturalHeight);
    setImageDimensions({ width, height });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setImageUrl('');

      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUrlChange = (event) => {
    setImageUrl(event.target.value);
    setImage(event.target.value);
  };

  useEffect(()=>{
    const elemsToCheck = { "Image": image || imageUrl, "Main Caption":caption, "Subtext": subtext };
    const missingFields = Object.keys(elemsToCheck).filter(key => !elemsToCheck[key]);
    if (missingFields.length > 0) {
      setError(`Missing fields: ${missingFields.join(', ')}`);
    } else {
      setError('');
    }
  }, [image,imageUrl,caption,subtext])


  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleSubtextChange = (event) => {
    setSubtext(event.target.value);
  };

  const handleSave = () => {
    if (image && caption) {
      const newRecord = { image,imageUrl, caption, subtext, captionColor, subtextColor, timestamp: Date.now() };
      const updatedRecords = [...records, newRecord];
      setRecords(updatedRecords);
      localStorage.setItem('demotivationalPosters', JSON.stringify(updatedRecords));
    }
  };

  const handleRemove = (index) => {
    const updatedRecords = records.filter((_, i) => i !== index);
    setRecords(updatedRecords);
    localStorage.setItem('demotivationalPosters', JSON.stringify(updatedRecords));
  };

  const handleRecordClick = (record) => {
    setImageUrl(record.imageUrl || "")
    setImage(record.image || "");
    setCaption(record.caption ||"");
    setSubtext(record.subtext||"");
    setCaptionColor(record.captionColor || COLOR_WHITE);
    setSubtextColor(record.subtextColor || COLOR_WHITE);
  };

 

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const handleTitleCase = () => {
    setSubtext(toTitleCase(subtext));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 py-6 flex">
      {/* Saved Posters Sidebar */}
      <div className={`bg-gray-900 text-white p-4 overflow-y-auto transition-all duration-300 ease-in-out ${isMinimized ? 'w-30' : 'w-80'}`}>
       
      <SavedPosters records={records} isMinimized={isMinimized} setIsMinimized={setIsMinimized} handleRecordClick={handleRecordClick} handleRemove={handleRemove} />

      </div>

      {/* Main Content */}
      <div className="flex-1 p-6">
        
        <div className="max-w-3xl sm:max-w-xl md:max-w-2xl mx-auto bg-white rounded-lg shadow-2xl p-8">
          <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Demotivational Poster Creator</h1>
          <div className="mb-6 flex">
            <div className="flex-1 flex items-center justify-between mb-4 mr-2">
              <button 
                onClick={() => fileInputRef.current.click()} 
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out flex items-center shadow-md"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                </svg>
                Upload Image
              </button>
              <input 
                ref={fileInputRef}
                type="file" 
                accept="image/*" 
                onChange={handleImageUpload}
                className="hidden"
              />
            
              <input 
                type="text"
                placeholder="Or enter image URL"
                value={imageUrl}
                onChange={handleImageUrlChange}
                className="flex-grow  p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
              />
              
            </div>
          </div>

          <div className="mb-6">
            <input
              type="text"
              placeholder="Enter main caption"
              value={caption}
              onChange={handleCaptionChange}
              className="w-full p-3 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
            />
            <div className="flex items-center">
              <span className="mr-2 text-gray-700">Caption Color:</span>
              <input
                type="color"
                value={captionColor}
                onChange={(e) => setCaptionColor(e.target.value)}
                className="w-10 h-10 rounded-full cursor-pointer"
              />
            </div>
          </div>

          <div className="mb-6">
            <div className="flex items-center mb-2">
              <input
                type="text"
                placeholder="Enter subtext"
                value={subtext}
                onChange={handleSubtextChange}
                className="flex-grow p-3 mr-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
              />
              <button 
                onClick={handleTitleCase}
                className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out shadow-md"
              >
                Title Case
              </button>
            </div>
            <div className="flex items-center">
              <span className="mr-2 text-gray-700">Subtext Color:</span>
              <input
                type="color"
                value={subtextColor}
                onChange={(e) => setSubtextColor(e.target.value)}
                className="w-10 h-10 rounded-full cursor-pointer"
              />
            </div>
          </div>
          
          {error && (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded" role="alert">
              <p className="font-bold"></p>
              <p>{error}</p>
            </div>
          )}
          
          <PosterEditor image={image} caption={caption} subtext={subtext} captionColor={captionColor} subtextColor={subtextColor} imageDimensions={imageDimensions}  posterRef={posterRef} handleImageLoad={handleImageLoad} handleSave={handleSave} setError={setError} />

        </div>
      </div>
    </div>
  );
};

export default App;