import React from 'react';
import { BORDER_WIDTH, BORDER_PADDING } from '../constants';

const PosterPreview = ({ image, caption, subtext, captionColor, subtextColor, imageDimensions,handleImageLoad,posterRef }) => {
  if(!image) return;
  return (
    <div className="preview mb-6 bg-black p-8 rounded-lg flex justify-center items-center shadow-inner"  ref={posterRef}>
      {image && (
              <div className="poster inline-block">
                <div 
                  className="flex justify-center items-center"
                  style={{
                    borderColor: captionColor,
                    borderWidth: BORDER_WIDTH,
                    borderStyle: 'solid',
                    padding: BORDER_PADDING,
                    width: imageDimensions.width + (2 * (BORDER_WIDTH + BORDER_PADDING)),
                    height: imageDimensions.height + (2 * (BORDER_WIDTH + BORDER_PADDING))
                  }}
                >
                  <img 
                    src={image} 
                    alt="Poster" 
                    onLoad={handleImageLoad}
                    className="max-w-full max-h-full object-contain" 
                    style={{ width: imageDimensions.width, height: imageDimensions.height }}
                  />
                </div>
                
                <div className="text-center mt-4" >
                  <h2 className="text-2xl font-bold" style={{ color: captionColor }}>{caption}</h2>
                  <p className="text-lg italic mt-2" style={{ color: subtextColor }}>{subtext}</p>
                </div>
              </div>
            )}
    </div>
  );
};

export default PosterPreview;