import { MAX_WIDTH, MAX_HEIGHT } from '../constants';

export const calculateDimensions = (imgWidth, imgHeight) => {

        let width = imgWidth;
        let height = imgHeight;
        
        if (width > MAX_WIDTH) {
          height = (MAX_WIDTH / width) * height;
          width = MAX_WIDTH;
        }
        
        if (height > MAX_HEIGHT) {
          width = (MAX_HEIGHT / height) * width;
          height = MAX_HEIGHT;
        }
        
        return { width, height };

};
